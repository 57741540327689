import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
};

export const pulseIdDetails = createSlice({
  name: "pulseId",
  initialState,
  reducers: {
    pulseIdDetail: (state, action) => {
        state.value = action.payload;
    },
  },
});
export const { pulseIdDetail } = pulseIdDetails.actions;

export default pulseIdDetails.reducer;
