import React, { useEffect, useState } from 'react';
import {
  newContactInit,
  contactDetailScheme,
} from '../../store/contacts/contactsUtils';
import { useFormik } from 'formik';
import styles from './Templates.module.css';
import CustomTable from '../../component/customTable/CustomTable';
import AppHeader from '../../component/appHeader/AppHeader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, debounce } from '@mui/material';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import { toast } from 'react-toastify';
import { signId_action } from '../../store/signIDDetails/signId_action';
import { getTemplatePreview } from '../../Api/TemplatePreviewApi';
import Skeleton from '@mui/material/Skeleton';

const tHead = [
  'Template Name',
  'Created By',
  'Created Time',
  'Modified By',
  'Modified Time',
];

const Templates = () => {
  const document = useSelector((state) => state?.document?.value);

  const dispatch = useDispatch();
  const navPath = Navigation();

  const keys = [
    'Template_Name',
    'Created_User_Name',
    'Created_Time',
    'Modified_User_Name',
    'Modified_Time',
  ];

  const [checkedId, setCheckedId] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageIndex, setPageIndex] = useState(false);
  const [toggleNewContact, setToggleNewContact] = useState(false);
  const [templateValues, setTemplateValues] = useState({});
  const [pageShow, setPageShow] = useState(false);
  const [page_idx, setPage_idx] = useState(0);
  const [page_size, setPage_size] = useState(10);

  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');



  useEffect(() => {
    fetchTemplate();
  }, []);


  const fetchTemplate = (idx = 0, size = 10, val, key) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_TEMPLATES, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: {
        org_id: document?.OrgId,
        limit: size ?? 10,
        ...(idx > 0 ? { cursor: key ? idx : idx + 1 } : { cursor: 1 }),
        ...(val?.length > 0 ? { search: val } : {}),
      },
    })
      .then((res) => {
        setContacts(res.data.templates_list);
        if (idx === 0) {
          setTotalRecord(res.data.total_record);
        }
        dispatch(toggleLoader(false))
        setPageShow(true)
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
    //  .finally(() => dispatch(toggleLoader(false)));
  };

  const debouncedSendRequest = debounce(fetchTemplate, 800);

  const handleSearch = (idx = 0, size = 10, val) => {
    const encode = encodeURIComponent(val);
    debouncedSendRequest(idx = 0, size = 10, encode);
  };

  const deleteTemplate = (_id, resetId = () => { }, pageSize, cursor) => {
    dispatch(toggleLoader(true));

    let data = JSON.stringify({
      org_id: document?.OrgId,
      ids: checkedId,
      limit: pageSize,
      cursor: cursor,
    });

    const headers = {
      'Content-Type': 'application/json',
    };

    axios.delete(process.env.REACT_APP_TEMPLATES, {
      headers: headers,
      data: data,
      withCredentials: process.env.REACT_APP_CREDENTIALS,
    })
      .then((res) => {
        if (res?.data?.templates_list?.length) {
          setContacts(res.data.templates_list);
          setTotalRecord(totalRecord - checkedId?.length);
        } else {
          fetchTemplate(cursor - 1, pageSize, null, 'delete');
          setPageIndex(true);
        }
        dispatch(toggleLoader(false))
        toast.success('Success');
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
    //  .finally(() => dispatch(toggleLoader(false)));

  };

  const contactDetail = useFormik({
    initialValues: newContactInit,
    enableReinitialize: true,
    validationSchema: contactDetailScheme,
    onSubmit: (values) => {
      setContacts((cur) => [...cur, values]);
      contactDetail.handleReset();
      setToggleNewContact(false);
    },
  });

  const convertKeysToLowerCase = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const lowerCaseKey = key.toLowerCase();
        newObj[lowerCaseKey] = obj[key];
      }
    }
    return newObj;
  };

  const handleEditTemplate = (item, edit_template) => {
    if (item.Template_Name !== undefined && (admin || show_integeration?.user_permission[2]?.read === 1)) {
      dispatch(toggleLoader(true));
      dispatch(signId_action(null));
      const id = item;
      item['Template_Id']= item.Id;
      getTemplatePreview(id, dispatch, (res) => {
        let data = res.data;
        const newObj = convertKeysToLowerCase(id);
        dispatch(signId_action(newObj));
        if (edit_template === 'edit-template') {
          navPath(`/edit-template/${data.template_information.Id}`, 'document_reload');
        } else {
          navPath(`/edit-as-new-template/${data.template_information.Id}`, 'document_reload');
        }
      }, (err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))
      });
    }
  };

  return (
    <>
      {pageShow ? (
        <Box className={styles.main_wrap}>
          <AppHeader
            btnText='Upload'
            moduleText='Templates'
            onBtnClick={() => navPath('/upload')}
            rolePermissions={show_integeration?.user_permission[1]}
            checkAdmin={admin}
          />
          <CustomTable
            getPageIndexSize={({ pageIdx, pageSize }) => {
              setPage_idx(pageIdx);
              setPage_size(pageSize);
              fetchTemplate(pageIdx, pageSize);
            }}
            page_idx={page_idx}
            page_size={page_size}
            totalRecordCount={totalRecord}
            editButton={true}
            pageDeleteIndex={pageIndex}
            pageSetIndex={(val) => setPageIndex(val)}
            searchName='Search in Templates'
            onSearch={({ pageIdx, pageSize, value }) => handleSearch(0, pageSize, value)}
            keys={keys}
            head={tHead}
            body={contacts}
            onDelete={deleteTemplate}
            getCheckedId={setCheckedId}
            handleEditTemplate={(item) => handleEditTemplate(item)}
            onRowClick={(val) => {
              setTemplateValues(val);
              handleEditTemplate(val, 'edit-template');
            }}
            rolePermissions={show_integeration?.user_permission[2]}
            checkAdmin={admin}
          />
        </Box>) : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}</>
  );
};

export default Templates;
