import { Box, Button, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getInit, getUstat } from "../Api/UserGetApi";
import { crm_param_action } from "../store/crmParam/crmParamAction";
import { integration_action } from "../store/integrations/integrationAction";
import { toggleLoader } from "../store/loader/loaderReducer";
import { upload_action } from "../store/uploadDocument/uploadAction";
import mondaySdk from "monday-sdk-js";
import styles from './MondayCRM.module.css';
import { useSelector } from "react-redux";
import { getSendSign } from "../Api/SendSignApi";
import { toast } from "react-toastify";
import RefreshIcon from '@mui/icons-material/Refresh';
import { welcome_img_action } from "../store/welcomeImage/welcomeImgAction";
import AppHeader from "../component/appHeader/AppHeader";
import AdbIcon from '@mui/icons-material/Adb';
import { getChannelConnect } from "../Api/UploadChannel";

const tooltip_text = `Welcome to Centilio Sign! We are delighted to introduce you to our reliable electronic signature solution, designed to streamline your company's operations seamlessly.`

const MondayCRM = ({ board_detail }) => {
    const [login, setLogin] = useState(false);
    const [orgId, setOrgId] = useState(false);
    const [org_id, setOrg_id] = useState(null);
    const [sendValue, setSendValue] = useState(false);
    const [reloadModal, setReloadModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [monday_app, setMonday_app] = useState(false);
    const [connect, setConnect] = useState(null);

    const crm_param = useSelector((state) => state.crm_param.value);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.state?.set_refresh) {
            setReloadModal(true);
            setConnect(true);
        } else if (location?.state?.set_login) {
            setLogin(true);
            setSendValue(true);
        } else {
            fetchUstat();
        }
    }, [location?.state]);

    const monday = mondaySdk();
    monday.execute('valueCreatedForUser');

    const fetchUstat = (key) => {
        let param = {};
        dispatch(toggleLoader(true));
        getUstat(param, (res) => {
            if (res?.data) {
                handleInitHandler(key);
            } else {
                setLogin(true);
                setSendValue(true);
                dispatch(toggleLoader(false));
            }
        }, (err) => {
            toast.error('Please refresh the page and try again.');
            dispatch(toggleLoader(false))
        });
    };

    const handleInitHandler = (key) => {
        let param = {};
        getInit(param, (res) => {
            let user_info = res?.data?.user_information;
            let data = {
                Email: user_info?.Email_Id,
                FirstName: user_info?.First_Name,
                LastName: user_info?.Last_Name,
                OrgId: res?.data?.default_organizations?.Id,
                Status: user_info?.Status,
                RoleName: user_info?.userRole
            };
            setOrg_id(data);
            if (res.data.CRM_Integration_Info?.length) {
                setConnect(true);
                let list = { integration_information: res?.data?.integration_information }
                dispatch(integration_action(list));
                let banner_info = res?.data?.show_banner_info?.length ? JSON.parse(res?.data?.show_banner_info)[0]?.banner_details?.MONDAY_WELCOME : null;
                if (key) {
                    handleSendSign(crm_param, data);
                } else {
                    handleCreateDoc(data);
                }

                if (banner_info) {
                    setOpen(true);
                }
                dispatch(upload_action(data));
                dispatch(welcome_img_action(res?.data));
            } else {
                dispatch(toggleLoader(false));
                setConnect(false);
            }
        }, (err) => {
            if (err?.response?.data === 'org_not_found') {
                setOrgId(true);
                setLogin(true);
            };
            setSendValue(true);
            setLogin(true);
            // toast.error('Please refresh the page and try again.');
            dispatch(toggleLoader(false));
        })
    };

    const handleLogin = () => {
        let url = process.env.REACT_APP_LOGIN?.split('/')[2] === 'test.centilio.com' ? 'https://test.centilio.com/account/#/login?source=monday' : 'https://account.centilio.com/#/login?source=monday';
        window.open(url, '_blank');
        setSendValue(true);
        setLogin(false);
        setTimeout(() => {
            setReloadModal(true);
        }, 0);
    };

    const handleOrganization = () => {
        window.open(process.env.REACT_APP_ACCOUNT, '_blank');
        setSendValue(true);
        setLogin(false);
        setTimeout(() => {
            setReloadModal(true);
        }, 0);
    };

    const handleCreateDoc = (org_value) => {
        let data = null;
        if (window?.location?.ancestorOrigins[0]?.includes("monday.com")) {
            let subdomain_url = window?.location?.ancestorOrigins[0];
            let subdomain = subdomain_url?.split('.')[0]?.substring(8);
            // let url = window?.location?.href;
            // let regex = /\/boards\/(\d+)\//;
            // let match = url?.match(regex);
            // let boardId = null;
            // let pulseId = "";
            // if (match && match[1]) {
            //     boardId = board_detail?.boardId;
            // }
            // regex = /\/pulses\/(\d+)/;
            // match = url.match(regex);
            // if (match && match[1]) {
            //     pulseId = board_detail?.boardViewId;
            // }
            data = {
                org_id: org_value?.OrgId,
                board_Id: board_detail?.boardId,
                pulse_Id: board_detail?.boardViewId,
                source: 1,
                monday_domain: subdomain
            }
        };

        new Promise((resolve, reject) => {
            axios.get(process.env.REACT_APP_CRMENCRPT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: data })
                .then((response) => {
                    dispatch(crm_param_action(response?.data?.crm_param));
                    handleSendSign(response?.data?.crm_param, org_value);
                })
                .catch((error) => {
                    dispatch(toggleLoader(false));
                    setSendValue(true);
                    toast.error('Please refresh the page and try again.');
                    if (error.response.data === "Monday_App_not_available") {
                        setMonday_app(true);
                        //   app_not_found_image.value = true;
                        //   refresh_btn.value = true;
                        //   welcome_text.value = i18n.get_i18n_value('sign.crm.monday.app.notfound');
                        //   var headerElement = document.getElementById("myHeader");
                        //   headerElement.classList.remove("login-header");
                        //   headerElement.classList.add("app_not_found_header");
                    }
                });
        })
    };

    const handleSendSign = (response, org_value) => {
        let params = {
            org_id: org_value?.OrgId,
            limit: 10,
            cursor: 1,
            advsearch: response
        };
        getSendSign(params, (res) => {
            dispatch(toggleLoader(false));
            if (res?.data?.sign_for_others_list?.length) {
                window.location.href = `${process.env.REACT_APP_SDK_MYDOCUMENT}/#/sdk/my-document?crmparam=${response}&pulseId=${board_detail?.itemId}`;
            } else {
                setSendValue(true);
            }
        }, (err) => {
            setSendValue(true);
            toast.error('Please refresh the page and try again.');
            dispatch(toggleLoader(false))
        })
    };

    const handleUpload = () => {
        if (board_detail?.itemId) window.location.href = `${process.env.REACT_APP_SDK_MYDOCUMENT}/#/sdk/my-document?crmparam=${crm_param}&pulseId=${board_detail?.itemId}`;
        else window.location.href = `${process.env.REACT_APP_SDK_MYDOCUMENT}/#/sdk/my-document?crmparam=` + crm_param;
    };

    const handleReload = () => {
        setReloadModal(false);
        fetchUstat('refresh');
    };

    const handleConnect = () => {
        dispatch(toggleLoader(true));
        let options = { org_id: org_id?.OrgId, channel_type: 5 };
        getChannelConnect(options, (res) => {
            setReloadModal(true);
            setConnect(true);
            dispatch(toggleLoader(false));
            let data = res.data.data;
            window.open(data, "_blank");
        }, (err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
        });
    };

    return (
        <Box style={{ backgroundColor: !login && open ? '#13214440' : null, width: '100%', height: '100%' }}>
            {connect || !org_id  ? <Box>
                {!login && !reloadModal && !monday_app && <AppHeader crmPages="monday_crm" />}
                {!monday_app ?
                    <Box className={styles.login_page}>
                        <Box className={styles.refresh_header}><img
                            src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                            alt='welcome-img'
                            style={{ width: '30px', height: '30px' }}
                        />
                            <Typography className={styles.centilio_sign}>
                                Centilio Sign
                            </Typography>
                        </Box>
                        {reloadModal &&
                            <Box>
                                <Box className={styles.reload_text}>Reload the webpage for the latest updates.</Box>
                                <Box className={styles.reload_button}>
                                    <Button variant='contained' className={styles.monday_reload_btn} onClick={handleReload}>
                                        <RefreshIcon sx={{ fontSize: 20, marginRight: '4px' }} />Refresh</Button>
                                </Box>
                            </Box>}
                        {((login || org_id) && sendValue) && !reloadModal && <>
                            <Typography className={styles.login_header}>Welcome to Centilio Application</Typography>
                            {login ? <Button variant="contained" className={styles.login_button} onClick={() => orgId ? handleOrganization() : handleLogin()}>{orgId ? 'Create Organization' : 'Login'}</Button> :
                                <Tooltip title={<div><Box className={styles.tooltip_text}>{tooltip_text}</Box><Button variant="contained" onClick={() => setOpen(false)} className={styles.next_btn}>Next</Button></div>} placement="bottom-end" arrow open={open} componentsProps={{
                                    tooltip: {
                                        sx: { color: 'var(--table-font-color)', backgroundColor: '#fff', boxShadow: 'var(--box-shadow) !important' }
                                    }, arrow: { sx: { color: '#fff', boxShadow: 'var(--box-shadow) !important' } }
                                }}
                                >
                                    <Button variant="contained" className={styles.create_button} onClick={() => handleUpload()}>Create Document</Button>
                                </Tooltip>}
                        </>}
                    </Box> :
                    <Box className={styles.monday_not_available}><AdbIcon /><Box className={styles.monday_app_text}>App is not available</Box></Box>}
            </Box> :
                connect !== null && <Box className={styles.login_page}>
                    <Typography className={styles.login_header}>Connect the monday CRM with Centilio sign</Typography>
                    <Button variant="contained" className={styles.login_button} onClick={() => handleConnect()}>Connect</Button>
                </Box>}
        </Box>
    )
};

export default MondayCRM;