import { Box } from "@mui/material";
import React, { useState } from "react";
import styles from "./PriceList.module.css";
import PriceTable from "./PriceTable";

const PriceList = () => {
  const [value, setValue] = useState(true);
  const [plan, setplan] = useState("annually");

  const planChange = (e) => {
    setValue(e);
    if (e) {
      setplan("annually");
    } else setplan("monthly");
  };

  return (
    <Box className={styles.payment_div}>
      <div className={styles.price_title}>
        Choose the plan that suits for you.
      </div>
      <div className={styles.checkbox_div}>
        <div className={styles.tab_bg}>
          <div
            className={`${styles.monthly_tab} ${value === false && styles.active
              }`}
            onClick={() => planChange(false)}
          >
            <span>Monthly</span>
          </div>
          <div
            className={`${styles.annually_tab} ${value === true && styles.active
              }`}
            onClick={() => planChange(true)}
          >
            <span>Yearly (Save 25%)</span>
          </div>
        </div>
      </div>
      <PriceTable plans={value} plan={plan} />
    </Box>
  );
};

export default PriceList;
